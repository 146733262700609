/**
 * 🚨 Take note that this config is meant to be used client side and therefore ONLY references public config
 * options. NEVER expose private values to the client!
 */

import React from 'react';
import { PublicConfigType } from '~/config/public.server';

type ConfigProviderProps = {
	children: React.ReactNode;
	config: PublicConfigType;
}

const ConfigContext = React.createContext<PublicConfigType | undefined>(undefined);

function ConfigProvider({ children, config }: ConfigProviderProps) {
	return (
		<ConfigContext.Provider value={config}>
			{children}
		</ConfigContext.Provider>
	);
}

function useConfig() {
	const context = React.useContext(ConfigContext);
	if (context === undefined) {
		throw new Error('useConfig must be used within a ConfigProvider');
	}

	return context;
}

export { ConfigProvider, useConfig };
